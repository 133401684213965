<template>
  <div class="md-layout md-layout-item md-size-100">
    <div class="md-layout-item md-size-100">
      <md-tabs class="md-primary" md-sync-route>
        <md-tab
          id="tab-transactions-list"
          md-label="Транзакции пользователя"
          :to="`/users/${$route.params.id}/transactions`"
          exact
        />
        <md-tab
          id="tab-resources-list"
          md-label="Ресурсы пользователя"
          :to="`/users/${$route.params.id}/resources`"
        />
      </md-tabs>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
